<template>
	<div class="open-account-userinfo">

		<h1 class="h1_title">{{translate('identity_information')}}</h1>

		<van-form @submit="nextStep()" :rules="rules">
			<h4 class="h4_title">{{translate('id_photo')}}</h4>
			<van-row class="label-content">
				<van-col span="12" style="text-align:center;">
					<van-image
						width="150"
						height="100"
						fit="contain"
						:src="idcode_front_url"
					/>
				</van-col>
				<van-col span="12" style="text-align:center;">
					<van-image
						width="150"
						height="100"
						fit="contain"
						:src="idcode_back_url"
					/>
				</van-col>
			</van-row>

			<h4 class="h4_title">{{translate('identity_information')}}</h4>

			<van-cell-group>
				<van-cell :label="translate('id_card_name')" :title="name" />
				<van-cell :label="translate('id_card_identity_number')" :title="idcode" />
				<van-cell :label="translate('id_card_gender')" :title="gender" />
				<van-cell :label="translate('id_card_date_of_birth')" :title="birthday" />
				<van-cell :label="translate('id_card_address')" :title="address" v-if="address"/>
				<van-cell :label="translate('id_card_nationality')" :title="nationality" v-if="nationality"/>
				<van-cell :label="translate('id_card_issuing_authority')" :title="authority" v-if="authority"/>
				<van-cell :label="translate('id_card_validity_period')" :title="validDate" v-if="validDate"/>
			</van-cell-group>

			<p style="color: blue;text-align: right;padding: 10px 16px 0 16px;font-size: 13px" @click="recall_face_verify">{{translate('incorrect_identity_information')}} ></p>

			<h4 class="h4_title">{{translate('other_information')}}</h4>

			<van-field
				readonly
				clickable
				:label="translate('marital_status')"
				label-width="80px"
				:value="marriages[indexMarriage]"
				:placeholder="translate('please_choose')"
				@click="showMarriage = true"
				required
				:rules="rules.marriage"
			/>
			<van-popup v-model="showMarriage" round position="bottom">
				<van-picker
					:title="translate('marital_status')"
					show-toolbar
					:columns="marriages"
					:default-index="indexMarriage"
					@cancel="showMarriage = false"
					@confirm="onConfirmMarriage"
				/>
			</van-popup>

			<van-field 
				v-model="formItem.email" 
				:label="translate('id_card_email')" 
				label-width="80px"
				:center="true"
				:placeholder="translate('please_fill_in_the_email')" 
				required
				:rules="rules.email"
				>
				<template #button>
					<van-button size="mini" type="primary" block native-type="button" :disabled="disabledSendCode" @click="sendCode">{{send_text}}</van-button>
				</template>
			</van-field>

			<van-field
				v-model="formItem.email_code" 
				:label="translate('verification_code')" 
				label-width="80px"
				:placeholder="translate('please_fill_verification_code')"
				required
				:rules="rules.email_code"
				/>
			
			<div class="common_block">
				<van-button type="warning" block round native-type="submit">{{translate('next_step')}}</van-button>
			</div>
		</van-form>
		
		<van-loading type="spinner" v-if="showLoading" color="#1989fa" style="position:fixed;top:50%;left:50%;transform: translate(-50%, -50%)" />	
	</div>
</template>

<script>
	export default {
		name: 'account_idcard',
		data() {
			return {
				formItem: {
					email: "",
					email_code: "",
					is_marriage: this.translate('unmarried'),
				},

				rules: {
					email: [
						{
							required:true,
							message: this.translate('please_enter_the_correct_email'),					
							pattern: /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
							trigger: 'onBlur'
						}
					],
					marriage: [
						{ 
							required: true, 
							trigger: 'onBlur', 
							message: this.translate('please_select_marital_status') 
						}
					],
					
					email_code: [
						{ 
							required: true, 
							message: this.translate('please_fill_right_verification_code'),
							pattern: /(^\d{6}$)/,
							trigger: 'onBlur'
						}
					]
				},

				token: "",

				name: "",
				gender: "",
				idcode: "",
				address: "",
				nationality: "",
				authority: "",
				birthday: "",
				validDate: "",

				indexMarriage: 0,
				showMarriage: false,
				marriages: [this.translate('unmarried'), this.translate('married')],

				resourceURL: this.$axios.defaults.resourceURL,
				idcode_front_url: this.$axios.defaults.resourceURL + "images/icon_id_u.png",
				idcode_back_url: this.$axios.defaults.resourceURL + "images/icon_id_g.png",

				send_text: this.translate('send_the_verification_code'),
				second: 60,
				clock: "",
				disabledSendCode: false,

				showLoading: false,

			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				this.init()
			}
		},
		methods: {

			doLoop() {
				this.second--;
				if(this.second > 0){
					this.send_text = this.second + this.translate('second')
				}else{
					clearInterval(this.clock); //清除js定时器
					this.disabledSendCode = false;
					this.send_text = this.translate('send_the_verification_code');
					this.second = 60; //重置时间
				}
			},


			sendCode() {

				var regEmail=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				
				if(this.formItem.email == "" || !regEmail.test(this.formItem.email)){
					this.$toast.fail(this.translate('please_enter_the_correct_email'))
					return false
				}

				this.disabledSendCode = true

				this.showLoading = true

				this.$axios({
					method: 'post',
					url: 'account_open/sendemailcode', 
					data: {
						token: this.token,
						email: this.formItem.email
					}
				}).then ((res) => {
					if (res.success) {

						this.showLoading = false

						this.$toast.success(this.translate('verification_code_sent_success'))
						
						this.send_text = this.second + this.translate('second')
						this.clock = setInterval(this.doLoop, 1000); //一秒执行一次
						
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},

			init(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						
						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							var userinfo_data = res.data.userinfo_data		
							
							if (userinfo_data.length != 0) {
								this.name = userinfo_data.name
								this.gender = userinfo_data.gender
								this.birthday = userinfo_data.birthday
								this.idcode = userinfo_data.idcode

								if (userinfo_data.address != undefined) {
									this.address = userinfo_data.address
								}
								
								if (userinfo_data.nationality != undefined) {
									this.nationality = userinfo_data.nationality
								}

								if (userinfo_data.authority != undefined) {
									this.authority = userinfo_data.authority
								}

								if (userinfo_data.startDate != undefined && userinfo_data.endDate != undefined) {
									this.validDate = userinfo_data.startDate + this.translate('id_card_to') + userinfo_data.endDate
								}
	
								this.idcode_front_url = userinfo_data.idcode_front_url
								this.idcode_back_url = userinfo_data.idcode_back_url
								
								this.formItem.email = userinfo_data.email
								this.formItem.is_marriage = userinfo_data.is_marriage

								this.marriages.forEach((item, key) => {
									if(item == userinfo_data.is_marriage){
										this.indexMarriage = key
									}								
								})
							}
						}
					} else {
						this.$toast.fail(res.error_text)
					}
				})
			},

			recall_face_verify(){
                if (window.JsObject != null) {
                    window.JsObject.recall_face_verify();
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "recall_face_verify"});
                }
			},

			onConfirmMarriage(value, index) {
				if (value) {
					this.formItem.is_marriage = value
					
					this.indexMarriage = index
				}
				
				this.showMarriage = false
			},

			nextStep() {
				this.$axios({
					method: 'post',
					url: 'account_open/update',
					data: {
						token: this.token,
						userinfo_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						this.$router.push('/account/type?token=' + this.token)
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
	}
</script>